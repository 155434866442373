/* eslint-disable max-len */
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { CacheService } from 'ionic-cache';
import { BehaviorSubject, Observable, concatMap, from, lastValueFrom, map, of, switchMap } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '../oidc.config';
import { environment } from 'src/environments/environment';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie'; // TODO: Remove on next release

// Services
import { UtilitiesService } from './utilities.service';
import { LoggerService } from './logger.service';
import { TokenExchangeService } from './utils/token-exchange.service';
import { SettingsService } from './settings.service';

// Components
import { SuccessfulPurchaseModalComponent } from '../pages/subscription/components/successful-purchase-modal/successful-purchase-modal.component';

// Native Plugins
import { CustomerInfo, PACKAGE_TYPE, Purchases, PurchasesEntitlementInfo, PurchasesOffering, PurchasesPackage, PurchasesStoreProduct } from '@revenuecat/purchases-capacitor';
import { Preferences } from '@capacitor/preferences';
import { PushSubscriptionState } from 'onesignal-cordova-plugin';
import { Dialog } from '@capacitor/dialog';

/**
 * This service manages all user specific data like
 * - User Subscription
 * - User Account
 * - Push Notification Settings (soon)
 * - User Data Exchange
 */

// Cache TTL
const ONE_HOUR = 60 * 60;
const SIX_HOURS = ONE_HOUR * 6;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = ONE_DAY * 7;
const TWO_WEEKS = ONE_WEEK * 2;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public hasActiveSubscription$: BehaviorSubject<SubscriptionSource> = new BehaviorSubject(null);
  private oauthService = inject(OAuthService);
  private utilitiesService = inject(UtilitiesService);
  private loggerService = inject(LoggerService);
  private cacheService = inject(CacheService);
  private httpClient = inject(HttpClient);
  private platform = inject(Platform);
  private modalController = inject(ModalController);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private tokenExchange = inject(TokenExchangeService);
  private settingsService = inject(SettingsService);

  /**
   * Loads the OAuth Config and and the Discovery Document
   * Call this function before interacting with OAuth Routes
   */
  async initOAuth() {
    try {
      this.oauthService.configure(authConfig);
      await this.oauthService.loadDiscoveryDocument();
    } catch (error) {
      console.error('Error loading OAuth Discovery Document:', error);
    }
  }

  /**
   * This functions helps to persist the user session after the app was terminated,
   * by saving the auth tokens using the capacitor preferences plugin and by copying
   * the tokens back from capacitor preferences to session storage on app launch.
   */
  async loadTokensFromPreferences() {

    await this.migrateCookiesToPreferences();

    // Storage Key from Angular OIDC Client
    const sessionStorageKeys = [
      'nonce',
      'PKCE_verifier',
      'session_state',
      'access_token',
      'granted_scopes',
      'access_token_stored_at',
      'expires_at',
      'refresh_token',
      'id_token',
      'id_token_claims_obj',
      'id_token_expires_at',
      'id_token_stored_at'
    ];

    // Get the value of each key from preferences and store it an a variable
    await Promise.allSettled(sessionStorageKeys.map(async key => {
      const { value } = await Preferences.get({ key });
      if (value) {
        this.tokenExchange.set(key, value);
      }
      return value;
    }));
  }


  /**
   * Open Unidy login page in in-app-browser
   */
  async login() {
    this.oauthService.configure(authConfig);
    try {
      await this.oauthService.loadDiscoveryDocumentAndLogin();
    } catch (error) {
      console.error('Error loading OAuth Discovery Document:', error);
      await Dialog.alert({
        title: 'Login Fehler',
        message: 'Leider konnte der Server nicht erreicht werden. Bitte versuche es später erneut.',
      });
    }
  }

  /**
   * Opens the login page of the app, do not use animations
   */
  async redirectToLogin() {
    await this.router.navigate(['/login'], { replaceUrl: true });
  }

  /**
   * Open Unidy login page in in-app-browser
   */
  // async loginWithInAppBrowser(authUrl: string): Promise<any> {

  //   if (!this.platform.is('capacitor')) {
  //     window.open(authUrl, '_self');
  //     return null;
  //   }

  //   await InAppBrowser.openWebView({
  //     title: 'Login',
  //     url: authUrl,
  //     toolbarType: ToolBarType.NAVIGATION
  //   });

  //   await new Promise((resolve) => {

  //     InAppBrowser.addListener('urlChangeEvent', async event => {
  //       if (event.url.startsWith(environment.unidy.callbackUrlApp) && event.url.includes('code=')) {

  //         // Stop url change event
  //         await InAppBrowser.removeAllListeners();
  //         await InAppBrowser.close();
  //         // await InAppBrowser.clearCookies(); // TODO: Update Plugin
  //         // -> https://github.com/Cap-go/capacitor-inappbrowser/issues/36

  //         const loginResponse = await this.handleCallbackUrl(event.url);

  //         // Run post login actions
  //         // Using this without await, so loginWithInAppBrowser() will resolve faster
  //         this.runPostLoginActions();

  //         resolve(loginResponse);
  //       }
  //     });
  //   });
  // }

  /**
   * Checks if the user is logged in and re-authenticates them if necessary
   */
  async isLoggedIn(): Promise<boolean> {
    const { value: refreshToken } = await Preferences.get({ key: 'refresh_token' });
    const { value: accessToken } = await Preferences.get({ key: 'access_token' });

    return Boolean(refreshToken) && Boolean(accessToken);
  }

  /**
   * Dissociate user data from all services like OneSignal, RevenueCat, Firebase
   * and invalidates the access token and the refresh token.
   */
  async logout(): Promise<void> {

    // IMPORTANT: Don't log out of revenue cat, otherwise
    // the user has to restore her/his in-app-purchases

    try {
      await this.cacheService.clearGroup('user');
      await this.validateAccessToken();
      await this.oauthService.revokeTokenAndLogout(null, true);
      // Check if user can still read premium content because he has an in-app-subscription
      if (this.hasActiveSubscription$.value === 'web') {
        this.setSubscriptionInActive();
      }
    } catch (error) {
      this.oauthService.logOut(true);
    }
    console.log('User logged out successfully');
  }

  /**
   * Get the access token of the current user
   */
  getAuthToken(): string {
    return this.oauthService.getAccessToken();
  }

  /**
   * Gets user incl. gravatar user avatar
   */
  getUser(): Observable<UserInfo | null> {
    return from(this.isLoggedIn()).pipe(
      switchMap(isLoggedIn => {
        if (!isLoggedIn) {
          return of(null);
        }

        const request = from(this.validateAccessToken()).pipe(
          concatMap(() => this.httpClient.get(environment.unidy.issuer + '/oauth/userinfo')),
          map((user: UserInfo) => ({
            ...user,
            avatar: {
              url: this.getGravatarUrl(user?.email)
            }
          }))
        );

        return this.cacheService.loadFromObservable('user', request, 'user', ONE_DAY);
      })
    );
  }

  async getUserId(): Promise<string> {
    const user: UserInfo = await lastValueFrom(this.getUser());
    return user?.sub;
  }

  /**
   * Update a subscription for the authrorized user. Requires a user based token which can be created by e.g. Authorization Code flow.
   *
   * @returns Subscriptions
   */
  async updateUser(data: any): Promise<any> {
    try {
      const userId = await this.getUserId();
      const request = this.httpClient.put(environment.unidy.issuer + '/api/v1/users/' + userId, data);
      return lastValueFrom(request) as Promise<any>;
    } catch (error) {
      console.error('Catched ERROR:', JSON.stringify(error));
    }
  }

  /**
   * Update a user object using the Unidy API
   *
   */
  async updateUserViaProxy(data: any): Promise<any> {
    try {
      await this.validateAccessToken();
      const token = this.tokenExchange.get('id_token');
      const userId = await this.getUserId();
      const options = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      const request = this.httpClient.patch('/api/unidy/users/' + userId, data, options);
      return lastValueFrom(request) as Promise<any>;
    } catch (error) {
      console.error('Catched ERROR:', JSON.stringify(error));
    }
  }

  /**
   * List all subscriptions for the authorized user. Requires a user based token
   * which can be created by e.g. Authorization Code flow.
   *
   * @returns Subscriptions
   */
  async getRemoteSubscriptions(): Promise<Subscription[]> {
    try {
      await this.validateAccessToken();
      const request = this.httpClient.get(environment.unidy.issuer + '/api/v1/subscriptions');
      const response = await lastValueFrom(request) as Subscriptions;

      return response.subscriptions;
    } catch (error) {
      console.error('Catched ERROR:', JSON.stringify(error));
      return [];
    }
  }

  /**
   * Creates a subscription for the authrorized user. Requires a user based token which can be created by e.g. Authorization Code flow.
   *
   * @returns Subscription
   */
  async createRemoteSubscription(subscription: Subscription): Promise<Subscription> {
    try {
      await this.validateAccessToken();
      const request = this.httpClient.post(environment.unidy.issuer + '/api/v1/subscriptions', subscription);
      return lastValueFrom(request) as Promise<Subscription>;
    } catch (error) {
      console.error('Catched ERROR:', JSON.stringify(error));
    }
  }

  /**
   * Show the subscription for the authrorized user. Requires a user based token which can be created by e.g. Authorization Code flow.
   *
   * @returns Subscriptions
   */
  async getRemoteSubscriptionById(subscriptionId: string): Promise<Subscriptions> {
    try {
      await this.validateAccessToken();
      const request = this.httpClient.get(environment.unidy.issuer + '/api/v1/subscriptions/' + subscriptionId);
      return lastValueFrom(request) as Promise<Subscriptions>;
    } catch (error) {
      console.error('Catched ERROR:', JSON.stringify(error));
    }
  }

  /**
   * Update a subscription for the authrorized user. Requires a user based token which can be created by e.g. Authorization Code flow.
   *
   * @returns Subscriptions
   */
  async updateRemoteSubscription(subscriptionId: string, subscription: Subscription): Promise<Subscriptions> {
    try {
      await this.validateAccessToken();
      const request = this.httpClient.put(environment.unidy.issuer + '/api/v1/subscriptions/' + subscriptionId, subscription);
      return lastValueFrom(request) as Promise<Subscriptions>;
    } catch (error) {
      console.error('Catched ERROR:', JSON.stringify(error));
    }
  }

  /**
   * Delete a subscription for the authrorized user. Requires a user based token which can be created by e.g. Authorization Code flow.
   *
   * @returns any
   */
  async deleteRemoteSubscription(subscriptionId: string): Promise<any> {
    try {
      await this.validateAccessToken();
      const request = this.httpClient.delete(environment.unidy.issuer + '/api/v1/subscriptions/' + subscriptionId);
      return lastValueFrom(request) as Promise<any>;
    } catch (error) {
      console.error('Catched ERROR:', JSON.stringify(error));
    }
  }

  /**
   * Get user subscriptions from WooCommerce ("Web subscriptions")
   * 
   * @returns WooCommerceSubscription
   */
  async getWooCommerceSubscriptions(): Promise<WooCommerceSubscriptions> {
    try {
      const email = this.oauthService.getIdentityClaims().email;
      const request = this.httpClient.get('https://corsproxy.io/?https://internal.btc-echo.de/api/v1/woocommerce/user-subscriptions?email=' + email);
      return lastValueFrom(request) as Promise<WooCommerceSubscriptions>;
    } catch (error) {
      console.error('Catched ERROR:', JSON.stringify(error));
    }
  }

  async getPurchases() {

    const user = await lastValueFrom(this.getUser());
    const request = this.httpClient.post(environment.hubspot.api + '/purchases/get', {
      user: user?.sub, // '88a159cf-e546-4397-b4cf-7e5e11bdb599'
      email: user?.email // 'marius@eyloo.com'
    });

    const response = await lastValueFrom(request) as any;

    return response;
  }

  async getOffering(offeringId?: string): Promise<PurchasesOffering> {

    if (!this.platform.is('capacitor')) {
      return null;
    }

    const { customerInfo } = await Purchases.getCustomerInfo();
    const offerings = await Purchases.getOfferings();

    const currentOffering = offeringId && (offeringId?.toLocaleLowerCase() in offerings.all) ? offerings.all[offeringId?.toLocaleLowerCase()] : offerings?.current; // Standard price or special offer
    const winbackOfferingId = (currentOffering?.metadata as any as CustomMetadata)?.config?.winbackOfferingId as string;
    const winbackOffering = winbackOfferingId ? offerings?.all[winbackOfferingId] : null;

    // console.log('getOffering - currentOffering', currentOffering);
    // console.log('getOffering - winbackOfferingId', winbackOfferingId);
    // console.log('getOffering - winbackOffering', winbackOffering);

    // Check again if user had purchases and display the correct text (eg. 1€ for the first month, then 9.99€ (or 7.99€ ))
    const isNewCustomer = !Boolean(customerInfo?.allPurchasedProductIdentifiers?.length);
    const latestExpirationDateMillis = customerInfo?.latestExpirationDate ? new Date(customerInfo.latestExpirationDate).getTime() : 0;
    const isExpiredOverWeek = latestExpirationDateMillis < Date.now() - TWO_WEEKS;
    const showWinBackOffering = !offeringId && (!isNewCustomer && Boolean(winbackOffering)) && !isExpiredOverWeek; // Custom offeringId overwrites winbackOffering

    // Show offering_2 if user already has purchased a product. Fallback to current offering.
    const offering: any = showWinBackOffering ? winbackOffering : currentOffering;

    // To make it easier to use in the template we edit the object so we don't have to decide between monthly and monthlyIntroPrice in the template
    if (isNewCustomer) {
      if (offering?.metadata?.general?.button && offering?.metadata?.general?.buttonIntroPrice) {
        offering.metadata.general.button = offering?.metadata?.general?.buttonIntroPrice;
      }
      delete offering?.metadata?.general?.buttonIntroPrice;
    } else {
      delete offering?.metadata?.general?.textIntroPrice;
    }

    console.log('getOffering - offering', offering);

    return offering as PurchasesOffering;
  }

  async getCustomerInfo(): Promise<CustomerInfo> {
    const { customerInfo } = await Purchases.getCustomerInfo();
    return customerInfo;
  }

  /**
   * CALL ON APP INIT! - Checks if the user has an active subscription on the web or in-app.
   * If the user has an active web subscription on unidy, the paywall will be hidden.
   * If the user has an in-app-subscription on unidy, no matter if active or not, the in-app-subscription state will be synced to unidy.
   */
  async checkSubscriptionState(): Promise<void> {

    try {

      console.log('USER - checkSubscriptionState');

      const isLoggedIn = await this.isLoggedIn();
      console.log('USER - isLoggedIn', isLoggedIn);

      if (isLoggedIn) {
        const subscriptions = await this.getRemoteSubscriptions();
        // If there is an active subscription, that has not the id of the app subscriptions, than it must be a web subscription. (Web subscription have different ids)
        const activeWebSubscription = subscriptions?.some(s =>
          s.state === 'active' &&
          s.subscription_category_id !== environment.unidy.subscriptionIdMonthly &&
          s.subscription_category_id !== environment.unidy.subscriptionIdYearly
        );

        console.log('USER - activeWebSubscription', activeWebSubscription);

        // Check if user has active subscription bought on the web
        // If so, hide the paywall and do no further checks
        if (activeWebSubscription) {
          this.setSubscriptionActive('web');
        } else {

          // Only execute on device
          if (!this.platform.is('capacitor')) {
            return null;
          }

          // If user has no active subscription that was bought on the web,
          // check if he has in-app-subscriptions. If yes, hide paywall.
          const { customerInfo } = await Purchases.getCustomerInfo();
          const hasActiveEntitlement = Boolean(Object.keys(customerInfo?.entitlements?.active)?.length);
          if (hasActiveEntitlement) {
            this.setSubscriptionActive('in-app');
          } else {
            this.setSubscriptionInActive();
          }

          // Check if the in-app-subscription is already synced to unidy
          const remoteInAppSubscriptions = subscriptions.filter(s =>
            s.subscription_category_id === environment.unidy.subscriptionIdMonthly ||
            s.subscription_category_id === environment.unidy.subscriptionIdYearly
          );
          const activeEntitlement = await this.getActiveEntitlement(customerInfo);

          console.log('USER - remoteInAppSubscriptions', remoteInAppSubscriptions);

          // If in-app-subscription exists on remote, update it if necessary
          if (remoteInAppSubscriptions?.length) {

            for (const remoteInAppSubscription of remoteInAppSubscriptions) {

              const updatedSubscription: Subscription = {};

              // If the in-app-subscription is not synced to unidy, create it.
              const product = await this.getProductById(remoteInAppSubscription?.metadata?.store_product?.value);
              const productExpirationDate = customerInfo?.allExpirationDates?.[product?.identifier];
              const productActive = product?.identifier === activeEntitlement?.productIdentifier;

              if (!product) {
                console.log('USER - Product not found');
                return;
              }

              // Migration of payment_frequency for yearly subscriptions
              if (this.iso8601ToText(product.subscriptionPeriod) !== remoteInAppSubscription.payment_frequency) {
                console.log('USER - payment_frequency', this.iso8601ToText(product.subscriptionPeriod));
                updatedSubscription.payment_frequency = this.iso8601ToText(product.subscriptionPeriod);
              }

              // Migration of subscription_category_id for yearly subscriptions
              if (((product.subscriptionPeriod === 'P1Y') && updatedSubscription.subscription_category_id !== environment.unidy.subscriptionIdYearly)
                || ((product.subscriptionPeriod === 'P1M') && updatedSubscription.subscription_category_id !== environment.unidy.subscriptionIdMonthly)) {
                console.log('USER - subscription_category_id', product.subscriptionPeriod === 'P1Y' ? environment.unidy.subscriptionIdYearly : environment.unidy.subscriptionIdMonthly);
                updatedSubscription.subscription_category_id = product.subscriptionPeriod === 'P1Y'
                  ? environment.unidy.subscriptionIdYearly
                  : environment.unidy.subscriptionIdMonthly;
              }

              // Check if expiration date changed
              if (productExpirationDate && activeEntitlement?.expirationDate && (activeEntitlement?.expirationDate !== remoteInAppSubscription?.ends_at)) {
                console.log('USER - expirationDate', activeEntitlement.expirationDate);
                console.log('USER - ends_at', remoteInAppSubscription.ends_at);
                updatedSubscription.ends_at = productExpirationDate;
              }

              // Check if active state changed
              if (productActive !== (remoteInAppSubscription.state === 'active')) {
                console.log('USER - subscription state', Boolean(activeEntitlement));
                updatedSubscription.state = productActive ? 'active' : 'inactive';
              }

              // Check if billing issue detected
              if (productActive && remoteInAppSubscription.payment_state === 'payed' && activeEntitlement?.billingIssueDetectedAt) {
                console.log('USER - unpayed');
                updatedSubscription.payment_state = activeEntitlement?.billingIssueDetectedAt ? 'not_payed' : 'payed';
              }

              if (Object.keys(updatedSubscription).length) {
                // Set updated_at to current date & time
                updatedSubscription.updated_at = new Date().toISOString();
                // Log object
                console.log('USER - Update subscription', updatedSubscription);
                // Send data to unidy
                await this.updateRemoteSubscription(remoteInAppSubscription.id, updatedSubscription);
              }
            }
          } else {

            if (!activeEntitlement?.productIdentifier) {
              console.log('USER - No active entitlement found');
              return;
            }

            // If the in-app-subscription is not synced to unidy, create it.
            const product = await this.getProductById(activeEntitlement?.productIdentifier);

            if (!product) {
              console.log('USER - Product not found');
              return;
            }

            const subscription: Subscription = {
              subscription_category_id: product.subscriptionPeriod === 'P1Y'
                ? environment.unidy.subscriptionIdYearly
                : environment.unidy.subscriptionIdMonthly, // Same as Unidy Subscription Category
              title: product?.title || 'Plus+', // App Store
              text: product?.description || 'Alle Artikel auf BTC-ECHO frei', // App Store
              reference: activeEntitlement?.identifier,
              ends_at: activeEntitlement?.expirationDate, // ISO String
              starts_at: activeEntitlement?.originalPurchaseDate, // ISO String
              payment_frequency: this.iso8601ToText(product.subscriptionPeriod),
              payment_state: activeEntitlement?.billingIssueDetectedAt ? 'not_payed' : 'payed',
              state: activeEntitlement?.isActive ? 'active' : 'inactive',
              // next_payment_at: null, // TODO
              price: product?.price || 9.99,
              metadata: {
                store: {
                  label: 'Store',
                  value: activeEntitlement?.store
                },
                store_product: {
                  label: 'Store Product ID',
                  value: product?.identifier || '?'
                },
                rc_user: {
                  label: 'RevenueCat User ID',
                  value: customerInfo?.originalAppUserId
                }
              }
            };

            console.log('USER - Create new subscription', subscription);

            await this.createRemoteSubscription(subscription);
          }
        }
      } else {

        // Only execute on device
        if (!this.platform.is('capacitor')) {
          return;
        }
        // If user is not logged in, check for in-app-purchases
        const { customerInfo } = await Purchases.getCustomerInfo();
        const hasInAppSubscription = Boolean(Object.keys(customerInfo.entitlements.active)?.length);
        console.log('USER - hasInAppSubscription', hasInAppSubscription);
        if (hasInAppSubscription) {
          this.setSubscriptionActive('in-app');
        } else {
          this.setSubscriptionInActive();
        }
      }
    } catch (error) {
      console.error('USER - checkSubscriptionState', error);
    }
  }


  /**
   * Performs an In-App-Purchase and syncs subscription to unidy
   *
   * @param identifier Package ID
   * @param offeringIdentifier Offering ID
   */
  public async purchaseInAppSubscription(aPackage: PurchasesPackage): Promise<{ success: boolean }> {

    if (!aPackage) {
      console.error('USER - purchaseInAppSubscription: No package found');
      return { success: false };
    }

    try {
      // Show loading
      await this.utilitiesService.presentLoading();

      // Purchase item
      const { customerInfo, transaction } = await Purchases.purchasePackage({
        aPackage
      });
      // Log purchase event
      await this.loggerService.logEvent('in_app_purchase', {
        currency: aPackage.product.currencyCode,
        value: aPackage.product.price,
        transaction_id: transaction?.transactionIdentifier,
        product_id: aPackage.product.identifier,
        quantity: 1
      });
      // Unlock product features
      if (Object.keys(customerInfo.entitlements.active)?.length) {

        const isLoggedIn = await this.isLoggedIn();
        if (isLoggedIn) {
          await this.checkSubscriptionState();
        } else {
          this.setSubscriptionActive('in-app');
        }
        this.showPostPurchaseModal(isLoggedIn);
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      this.utilitiesService.displayError(
        null, 'subscription-fade-out: purchaseSubscription', 'Der Kauf konnte nicht abgeschlossen werden.'
      );
      return { success: false };
    } finally {
      await this.utilitiesService.dismissLoading();
    }
  }

  /**
   * Restore in App Purchase of current user
   */
  async restoreInAppPurchase() {
    try {
      // Show loading
      await this.utilitiesService.presentLoading();
      // Purchase item
      const { customerInfo } = await Purchases.restorePurchases();

      console.log('USER - customerInfo', customerInfo);

      if (Object.keys(customerInfo.entitlements.active)?.length) {

        const isLoggedIn = await this.isLoggedIn();
        if (isLoggedIn) {
          await this.checkSubscriptionState();
        } else {
          this.setSubscriptionActive('in-app');
        }
      } else {
        this.noPurchasesFound();
      }
    } catch (error) {
      this.utilitiesService.displayError(
        null, 'subscription-fade-out: purchaseSubscription', 'Der Kauf konnte nicht abgeschlossen werden.'
      );
      const isLoggedIn = await this.isLoggedIn();
      if (!isLoggedIn) {
        this.noPurchasesFound();
      }
    } finally {
      await this.utilitiesService.dismissLoading();
    }
  }

  async noPurchasesFound() {
    const { value } = await Dialog.confirm({
      title: 'Kein Abo gefunden',
      message: 'Wir konnten kein aktives Abo feststellen, das auf diesem Gerät gekauft wurde. Wenn du stattdessen im Web gekauft hast, logge dich bitte ein.'
    });
    if (value) {
      this.login();
    }
  }

  /**
   * Get the users active in-app entitlement
   */
  async getActiveEntitlement(customerInfo: CustomerInfo): Promise<PurchasesEntitlementInfo | null> {

    if (!customerInfo?.entitlements) {
      return null;
    }

    const entitlementIds = Object.keys(customerInfo.entitlements.all);
    const activeEntitlementId = entitlementIds.find(entitlementId => customerInfo.entitlements.all[entitlementId].isActive);
    const entitlement = customerInfo.entitlements.all[activeEntitlementId];

    console.log('USER - customerInfo', customerInfo);
    console.log('USER - entitlement', entitlement);

    return entitlement?.productIdentifier ? entitlement : null;
  }

  /**
   * Get active in-app-product
   */
  async getProductById(productId: string): Promise<PurchasesStoreProduct | undefined> {
    if (productId) {
      const offerings = await Purchases.getOfferings();
      const products = Object.keys(offerings.all).flatMap(offeringId => offerings.all[offeringId].availablePackages.map(p => p.product));
      const product = products.find(p => p.identifier === productId);

      console.log('USER - in-app-product', product);

      return product;
    } else {
      return undefined;
    }
  }

  /**
   * Makes hasActiveSubscription() returns true
   */
  setSubscriptionActive(source: 'web' | 'in-app') {
    // Prevent unnecessary executions
    if (!this.hasActiveSubscription$.value) {
      this.hasActiveSubscription$.next(source);
    }
  }

  setSubscriptionInActive() {
    // Prevent unnecessary executions
    if (this.hasActiveSubscription$.value) {
      this.hasActiveSubscription$.next(null);
    }
  }

  /**
   * Set OneSignal Data in RevenueCat
   */
  async sendOneSignalDataToRevenueCat(previous: PushSubscriptionState, current: PushSubscriptionState) {

    try {
      const attributes: {
        [key: string]: string;
      } = {};

      // User ID
      if ((previous?.id !== current?.id) && typeof current?.id === 'string') {
        attributes.$onesignalUserId = current.id;
      }

      // Push Token
      if ((previous?.token !== current?.token) && typeof current?.token === 'string') {
        if (this.platform.is('ios')) {
          attributes.$apnsTokens = current.token;
        } else if (this.platform.is('android')) {
          attributes.$fcmTokens = current.token;
        }
      }

      // Send to RevenueCat
      if (Object.keys(attributes)?.length) {
        await Purchases.setAttributes(attributes);
        console.log('USER - sendOneSignalDataToRevenueCat', attributes);
      }

    } catch (error) {
      console.error('Error sending OneSignal Data to RevenueCat', error);
    }
  }

  /**
   * Send the user data to the following services:
   * - RevenueCat
   * - OneSignal
   * - Firebase Analytics
   * - Sentry
   */
  async syncUserToThirdParty() {

    const user = await lastValueFrom(this.getUser());

    if (!user || !this.platform.is('capacitor')) {
      return;
    }

    const appUserID = user?.sub;
    const appUserEmail = user?.email;
    const appUserName = user?.name;

    // RevenueCat
    // https://www.revenuecat.com/docs/subscriber-attributes
    try {
      const attributes: {
        [key: string]: string;
      } = {};
      if (appUserID) {
        attributes.UnidyID = appUserID;
      }
      if (appUserEmail) {
        attributes.$email = appUserEmail;
      }
      if (appUserName) {
        attributes.$displayName = appUserName;
      }
      await Purchases.setAttributes(attributes);
      console.log('USER - syncUserToThirdParty - RevenueCat', attributes);
    } catch (error) {
      console.error(error);
    }

    // Send data to Senty and Firebase
    await this.loggerService.setUser({
      id: appUserID,
      username: appUserName,
      email: appUserEmail
    });
  }

  async loginRevenueCat() {

    if (!this.platform.is('capacitor')) {
      return;
    }

    const userId = await this.getUserId();
    if (userId) {
      await Purchases.logIn({ appUserID: userId });
    }
  }

  /**
   * Get the user's mail newsletter subscriptions
   *
   * @returns array of newsletter objects
   */
  async getHubspotNewsletterSubscriptions(): Promise<any> {
    // get user
    const user = await this.getUser().toPromise();
    if (!user) {
      return;
    }

    // get hubspot contact
    const response = await fetch(environment.hubspot.api + '/newsletter/get', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: user.email
      })
    }).then(res => res.json()).then(res => res.response.subscriptionStatuses).catch(error => {
      console.error('Hubspot Contact Error', error);
    });

    return response;
  }

  /**
   * Subscribe user to a newsletter
   *
   * @param subscriptionId id of the newsletter
   */
  async subscribeNewsletter(subscriptionId: string): Promise<any> {
    // get user
    const user = await this.getUser().toPromise();
    if (!user) {
      return;
    }

    // subscribe user
    const response = await fetch(environment.hubspot.api + '/newsletter/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: user.email,
        subscriptionId
      })
    }).then(res => res.json()).catch(error => {
      console.error('Hubspot Contact Error', error);
    });

    return response;
  }

  /**
   * Unsubscribe user from a newsletter
   *
   * @param subscriptionId id of the newsletter
   */
  async unsubscribeNewsletter(subscriptionId: string): Promise<any> {
    // get user
    const user = await this.getUser().toPromise();
    if (!user) {
      return;
    }

    // unsubscribe user
    const response = await fetch(environment.hubspot.api + '/newsletter/unsubscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: user.email,
        subscriptionId
      })
    }).then(res => res.json()).catch(error => {
      console.error('Hubspot Contact Error', error);
    });

    return response;
  }


  /**
   * Shows the "thank you" modal or the "account advantages" depending on the login state
   */
  private async showPostPurchaseModal(isUserLoggedIn: boolean) {
    const modal = await this.modalController.create({
      component: SuccessfulPurchaseModalComponent,
      backdropDismiss: false,
      handle: false,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      cssClass: 'auto-height transparent',
      componentProps: {
        isUserLoggedIn
      }
    });
    await modal.present();
  }

  /**
   * Logs in the user by using the auth code in the callback url
   * Also sends user data to third party provides and checks subscription state
   */
  private async handleCallbackUrl(url: string): Promise<void> {

    if (!url || (typeof url !== 'string')) {
      return;
    }

    const urlObj = new URL(url);
    // Append url params to current url so oidc library can grab it
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: Object.fromEntries(urlObj.searchParams),
      queryParamsHandling: 'merge',
      skipLocationChange: false
    });
    try {
      // Start new user session with current url callback
      await this.oauthService.tryLoginCodeFlow();
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * Checks if access token is valid and refreshes it if necessary.
   * Call this before every oauth request.
   */
  private async validateAccessToken() {
    try {
      const refreshTokenAvailable = Boolean(this.oauthService.getRefreshToken());
      console.log('USER - refreshTokenAvailable', refreshTokenAvailable);

      const accessToken = this.oauthService.getAccessToken();
      console.log('USER - accessTokenAvailable', accessToken);

      const hasValidAccessToken = this.oauthService.hasValidAccessToken();
      console.log('USER - hasValidAccessToken', hasValidAccessToken);

      if (refreshTokenAvailable && !hasValidAccessToken) {
        console.log('Access token expired. Renewing...');
        await this.oauthService.refreshToken();
      }
    } catch (error) {
      await Dialog.alert({
        title: 'Token Fehler',
        message: error?.message
      });
      // Remove invalid tokens
      if (error?.status === 400 || error?.status === 401) {
        this.oauthService.logOut();
      }
      console.log(error);
    }
  }

  /**
   * Get the gravatar url
   *
   * @param email email of the user
   * @returns url
   */
  private getGravatarUrl(email: string) {

    if (typeof email !== 'string') {
      return null;
    }

    const md5 = CryptoJS.MD5(email.trim().toLocaleLowerCase());
    return `https://www.gravatar.com/avatar/${md5}.jpg?d=mp&s=200`;
  }


  /**
   * Temporary! Can be deleted after 3 releases. Latest: 5.0.6
   *
   * This is a temporarily solution to move existing OIDC Cookies to
   * Capacitor Preferences because it seems to be more persistent than Cookies.
   *
   * @returns true if cookies were already migrated
   * or false when cookies weren't migrated
   */
  private async migrateCookiesToPreferences(): Promise<boolean> {

    if (!this.platform.is('capacitor')) {
      return;
    }

    const sessionStorageKeys = [
      'nonce',
      'PKCE_verifier',
      'session_state',
      'access_token',
      'granted_scopes',
      'access_token_stored_at',
      'expires_at',
      'refresh_token',
      'id_token',
      'id_token_claims_obj',
      'id_token_expires_at',
      'id_token_stored_at'
    ];

    const { value: cookiesMigrated } = await Preferences.get({ key: 'cookies_migrated' });

    if (!cookiesMigrated) {
      const result = await Promise.allSettled(sessionStorageKeys.map(async key => {
        const value = Cookies.get(key);
        if (value) {
          this.tokenExchange.set(key, value); // Move value to variable to use in runtime
          await Preferences.set({ key, value }); // Save value in preferences
        }

        // Note that the method has been executed, to execute migration only once
        await Preferences.set({ key: 'cookies_migrated', value: 'yes' });
        return value;
      }));
      console.log('Cookie Migration Result:', result);
    }

    return Boolean(cookiesMigrated);
  }

  /**
   * Converts an ISO 8601 duration string to a human readable text
   * @param isoDuration P1M, P1Y, P1W, P1D, PT1H, PT1M, PT1S
   * @returns monthly | yearly | weekly | daily | hourly | every minute | every second | unknown period
   */
  private iso8601ToText(isoDuration: string): string {
    // This regex matches the duration part of the ISO 8601 string
    const durationRegex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?T?(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
    const match = isoDuration.match(durationRegex);

    if (!match) {
      return 'monthly';
    }

    const [_, years, months, weeks, days, hours, minutes, seconds] = match.map(value => parseInt(value, 10) || 0);

    // Determine the period based on the parsed values
    switch (true) {
      case years > 0:
        return 'yearly';
      case months > 0:
        return 'monthly';
      case weeks > 0:
        return 'weekly';
      case days > 0:
        return 'daily';
      case hours > 0:
        return 'hourly';
      case minutes > 0:
        return 'every minute';
      case seconds > 0:
        return 'every second';
      default:
        return 'unknown period';
    }
  }
}

interface UserEvent {
  type: 'login' | 'logout';
}

export interface UserInfo {
  sub?: string; // User ID
  email: string;
  email_verified: boolean;
  given_name?: string;
  family_name?: string;
  name?: string;
  gender?: string;
  updated_at: string;
  birthdate?: string;
  phone_number?: string;
  address?: Address;
  avatar: {
    url: string;
  };
  active_subscriptions: Record<string, boolean>;
}

export interface Address {
  formatted: string;
  street_address: string;
  locality: string;
  region?: string;
  country: string;
  postal_code: string;
  address_line_1: string;
  address_line_2?: string;
  street: string;
  house_number: string;
  country_code: string;
  company?: string;
}

export interface Subscription {
  id?: string;
  title?: string;
  text?: string;
  payment_frequency?: string; // 'monthly' | 'yearly';
  metadata?: {
    [key: string]: {
      label: string;
      value: string;
    };
  };
  wallet_export?: {
    qr_code: string;
    further_information: string;
    additional_attributes: string[];
  };
  state?: 'active' | 'inactive';
  reference?: string;
  payment_state?: 'payed' | 'not_payed';
  created_at?: string;
  updated_at?: string;
  starts_at?: string;
  ends_at?: string;
  next_payment_at?: string;
  price?: number;
  user_id?: string;
  subscription_category_id?: string;
}

export interface Subscriptions {
  subscriptions: Subscription[];
}

export interface SubscriptionState {
  active: boolean;
};

export type SubscriptionSource = 'web' | 'in-app' | null;

export interface CustomMetadata {
  config?: {
    winbackOfferingId?: string;
    preselectedPackage?: string;
    countdown?: string | 'expiry_date';
  };
  general: {
    bullet_points: string[];
    button: string;
    buttonIntroPrice?: string;
    header: string;
    textIntroPrice?: string;
    offerBarText?: string;
  };
  monthly: {
    header: string;
    label: string;
    badge?: string;
  };
  yearly?: {
    header: string;
    label: string;
    badge?: string;
  };
}

export interface WooCommerceSubscription {
  id: number;
  status: 'active' | 'on-hold' | 'pending-cancel' | 'cancelled' | 'expired';
  start_date: string;
  next_payment: string;
  total: string;
  products: {
    product_id: number;
    name: string;
    quantity: number;
    total: string;
  }[];
}

export interface WooCommerceSubscriptions {
  usersubscriptions: WooCommerceSubscription[];
}
